import { Link, StaticQuery, graphql } from "gatsby"
import { Container, Col, Row } from "react-bootstrap"
import PropTypes from "prop-types"
import React from "react"

const Footer = ({ siteTitle, author, email }) => (
    <StaticQuery
    query={graphql`
        query FooterMeta {
            site {
                siteMetadata {
                    google {
                        googleMapAPIKey
                    }
                }
            }
        }
    `}
    render={data => (
        <>
        <footer>
            <Container>
                <Row>
                    <Col xs='12' sm='12' md='12' lg='12'>
                        <p><Link to='/'>Home</Link> | <Link to="/about/">About Us</Link> | <Link to="/services/">Services</Link> | <Link to="/our-work/">Our Work</Link> | <Link to="/contact/">Contact Us</Link></p>
                        <p>Copyright &copy; {new Date().getFullYear()} {siteTitle} All rights reserved.</p>
                        <div className="icons"></div>
                    </Col>
                </Row>
            </Container>
        </footer>
        </>
    )}
    />
)

Footer.propTypes = {
    siteTitle: PropTypes.string,
    author: PropTypes.string,
    email: PropTypes.string
}

Footer.defaultProps = {
    siteTitle: ``,
    author: ``,
    email: ``
}

export default Footer

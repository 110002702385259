import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

import "../../node_modules/bootstrap/scss/bootstrap.scss"
import "./scss/global.scss"

import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown, faChevronRight, faHome, faTools, faToolbox } from '@fortawesome/free-solid-svg-icons'

library.add(faChevronDown, faChevronRight, faHome, faTools, faToolbox)

const Layout = ({ children, page }) => (
    <StaticQuery
    query={graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                    author
                    emailUrl
                    google {
                        googleAnalyticsId
                    }
                }
            }
        }
    `}
    render={data => (
        <>
            <div className='wrapper'>
                <Header pageName={page} />
                <main>{children}</main>
                <Footer siteTitle={data.site.siteMetadata.title} author={data.site.siteMetadata.author} email={data.site.siteMetadata.emailUrl} />
            </div>
        </>
    )}
    />
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    page: PropTypes.string
}

export default Layout

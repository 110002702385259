import { Link } from 'gatsby'
import { Container, Col, Row } from 'react-bootstrap'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import React from 'react'

import Logo from '../images/logo.png'

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.pageName = this.props.pageName
        this.state = {isToggleOn: false}

        this.handleClick = this.handleClick.bind(this)
    }
    
    handleClick() {
        this.setState(state => ({
            isToggleOn: !state.isToggleOn
        }))
    }
    
    render() {
        return (
            <header>
                {typeof window !== 'undefined' ? this.state.isToggleOn ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll') : ''}
                <div className={`overlay ${this.state.isToggleOn ? 'openMenu' : ''}`}></div>
                <Container>
                    <Row>
                        <Col xs='12' sm='12' md='12' lg='12'>
                            <Navbar expand='all'>
                                <Navbar.Brand>
                                    <Link to='/'><img src={Logo} className='logo img-responsive' alt='Ochoa Enterprises' /></Link>
                                </Navbar.Brand>
                                <Navbar.Toggle id='hamburger' aria-controls='navigator' className={`hamburger hamburger--spin ${this.state.isToggleOn ? 'is-active' : ''}`} onClick={this.handleClick}>
                                    <div className='hamburger-box'>
                                        <div className='hamburger-inner'></div>
                                    </div>
                                </Navbar.Toggle>
                                <Navbar.Collapse id='navigator'>
                                    <Nav id='navigation' activeKey={this.pageName}>
                                        <Nav.Item>
                                            <Nav.Link href='/' eventKey='index'>Home</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link href='/about/' eventKey='about'>About Us</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link href='/services/' eventKey='services'>Services</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link href='/our-work/' eventKey='our-work'>Our Work</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link href='/contact/' eventKey='contact'>Contact Us</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Navbar.Collapse>     
                            </Navbar>
                        </Col>
                    </Row>
                </Container>
            </header>
        )
    }
}

export default Header
